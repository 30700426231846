<!-- 
	This is the Dashboards default page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div class="dashboard">
    <h2 class="p-15">数据看板</h2>
    <div class="marquee" v-show="notifications.length > 0">
      <div
          class="marquee-content"
          :style="{ 'animation-duration': computedDuration + 's' }"
      >
        <!-- 重复通知内容以实现循环 -->
        <template v-for="repeatIndex in 2">
        <span
            v-for="(item, index) in notifications"
            :key="`item-${repeatIndex}-${index}`"
            class="marquee-item"
        >
          {{ item.content }}
        </span>
        </template>
      </div>
    </div>
		<a-row :gutter="24" type="flex" align="stretch" style="width: 50%">
<!--			<a-col :span="24" :lg="12">-->

<!--				<h2 class="p-15">数据看板</h2>-->

				<!-- Counter Widgets -->
<!--				<a-row :gutter="24" style="width: 75.5%;left: 10px">-->
				<a-row :gutter="24" style="width: 100%;left: 10px">
					<a-col :span="24" :lg="24" :xl="12" class="mb-24" v-for="(stat, index) in stats" :key="index" style="position: relative; z-index: 1;">
						<!-- Widget 1 Card -->
						<WidgetCounter
							:title="stat.title"
							:value="stat.value"
							:prefix="stat.prefix"
							:suffix="stat.suffix"
							:icon="stat.icon"
							:status="stat.status"
						></WidgetCounter>
						<!-- / Widget 1 Card -->
					</a-col>
        </a-row>

				<!-- / Counter Widgets -->

				<!-- 充值 Table -->
<!--        <a-row :gutter="24" style="width: 100%;left: 10px">-->
<!--          <a-col :span="24" :lg="24" class="mb-24" style="position: relative; z-index: 1;">-->

<!--            &lt;!&ndash; 充值 Card &ndash;&gt;-->
<!--            <CardCharge></CardCharge>-->
<!--            &lt;!&ndash; / 充值 Card &ndash;&gt;-->

<!--          </a-col>-->
<!--        </a-row>-->

				<!-- / Sales By Country Table -->

        <a-row :gutter="24" style="width: 100%;left: 10px">
            <a-col :span="24" :lg="24" class="mb-24" style="position: relative; z-index: 1;">

              <!-- 申明 -->
              <CardAffirm></CardAffirm>
              <!-- / 申明 -->

            </a-col>
        </a-row>
		</a-row>

		<div>

			<!-- 3D Globe -->
			<widget-globe></widget-globe>
			<!-- 3D Globe -->

		</div>

		<!-- Charts -->
<!--		<a-row :gutter="24" type="flex" align="stretch">-->
<!--			<a-col :span="24" :lg="10" class="mb-24">-->

<!--				&lt;!&ndash; Active Users Card &ndash;&gt;-->
<!--				<CardBarChart></CardBarChart>-->
<!--				&lt;!&ndash; Active Users Card &ndash;&gt;-->

<!--			</a-col>-->
<!--			<a-col :span="24" :lg="14" class="mb-24">-->
<!--				-->
<!--				&lt;!&ndash; Sales Overview Card &ndash;&gt;-->
<!--				<CardLineChart></CardLineChart>-->
<!--				&lt;!&ndash; / Sales Overview Card &ndash;&gt;-->

<!--			</a-col>-->
<!--		</a-row>-->
		<!-- / Charts -->

	</div>
</template>

<script>

	import WidgetGlobe from '../components/Widgets/WidgetGlobe' ;

	import CardCharge from '../components/Cards/CardCharge' ;
	import CardAffirm from '../components/Cards/Affirm' ;

	// Bar chart for "Active Users" card.
	import CardBarChart from '../components/Cards/CardBarChart' ;

	// Line chart for "Sales Overview" card.
	import CardLineChart from '../components/Cards/CardLineChart' ;

	// Counter Widgets
	import WidgetCounter from '../components/Widgets/WidgetCounter' ;

	// Information card 1.
	import CardInfo from '../components/Cards/CardInfo' ;

  import http from "../utils/http"

	// Counter Widgets stats

	const stats = [
		{
			title: "累计盈利（实盘）",
			value: 0,
			prefix: "$",
			// suffix: "+30%",
			icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z" fill="#111827"/>
							<path d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z" fill="#111827"/>
							<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z" fill="#111827"/>
						</svg>`,
		},
		{
			title: "未实现盈利（实盘）",
			value: 0,
      prefix: "$",
			// suffix: "+20%",
      icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z" fill="#111827"/>
							<path d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z" fill="#111827"/>
							<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z" fill="#111827"/>
						</svg>`,
		},
		// {
		// 	title: "剩余盈利额度（实盘）",
		// 	value: 0,
    //   prefix: "$",
		// 	// status: "danger",
		// 	// suffix: "-1",
		// 	icon: `
		// 				<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		// 					<path fill-rule="evenodd" clip-rule="evenodd" d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z" fill="#111827"/>
		// 				</svg>`,
		// },
		{
			title: "累计盈利（模拟盘）",
			value: 0,
			prefix: "$",
			// suffix: "-10%",
      icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z" fill="#111827"/>
							<path d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z" fill="#111827"/>
							<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z" fill="#111827"/>
						</svg>`,
		},
    {
			title: "未实现盈利（模拟盘）",
			value: 0,
			prefix: "$",
			// suffix: "+10%",
      icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z" fill="#111827"/>
							<path d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z" fill="#111827"/>
							<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z" fill="#111827"/>
						</svg>`,
		},
    // {
    //   title: "剩余盈利额度（模拟盘）",
    //   value: 0,
    //   prefix: "$",
    //   // status: "danger",
    //   // suffix: "-1",
    //   icon: `
		// 				<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		// 					<path fill-rule="evenodd" clip-rule="evenodd" d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z" fill="#111827"/>
		// 				</svg>`,
    // },
	] ;

	export default ({
		components: {
			CardCharge,
      CardAffirm,
			WidgetGlobe,
			CardBarChart,
			CardLineChart,
			WidgetCounter,
			CardInfo,
		},
		data() {
			return {
        baseDuration: 20, // 控制滚动速度，数值越小，滚动越快
        notifications: [
          { id: 1, content: '当前为产品测试期，可免费使用。未来将对于本金2000U以下的用户实行永久免费政策。本金高于1万U的用户，建议联系客服购买私有化部署版本，避免服务对外暴露，遭遇不必要的网络攻击。' },
          // { id: 2, content: '由于工具的特殊属性，存在诸多不可抗力因素。请添加官方客服微信：copyapes_admin，加入产品交流群，如遇机房维护、服务宕机等情况将在群内做通知。' },
          // { id: 3, content: '这是第三条通知' },
          // 更多通知...
        ],
				// Counter Widgets Stats
				stats,

        dataLoaded: false,
			}
		},
    methods: {
      async fetchData() {
        try {
          const token = localStorage.getItem("token");
          const response = await http.get("/api/quotainfo/?token=" + token);
          const data = response.data.data[0];
          this.stats[0].value = parseFloat(data.pnl_0).toFixed(2);
          this.stats[2].value = parseFloat(data.pnl_1).toFixed(2);
          this.stats[1].value = parseFloat(data.upl_0).toFixed(2);
          this.stats[3].value = parseFloat(data.upl_1).toFixed(2);
          // this.stats[2].value = parseFloat(data.quota_0).toFixed(2);
          // this.stats[5].value = parseFloat(data.quota_1).toFixed(2);
          this.dataLoaded = true;
          console.log(data);
          console.log(this.stats);
        } catch (error) {
          console.error("请求失败:", error);
        }
      },
    },
    created() {
      this.fetchData();
    },
    computed: {
      computedDuration() {
        // 假设每个通知字符增加的时间为0.5秒，可以根据需要调整
        const extraTimePerChar = 0.1;
        const totalChars = this.notifications.reduce((total, item) => total + item.content.length, 0);
        return this.baseDuration + (totalChars * extraTimePerChar);
      }
    }
	})

</script>

<style lang="scss" scoped>
	$md: 768px ;

	.dashboard::v-deep {
		.globeContainer {
			position: absolute;
			top: 0;
			right: 0;
			margin-top: 15rem;
			margin-right: 6rem;
		}
		.ant-row-flex {
			position: relative;
			z-index: 1;

			@media(min-width: $md) {
				position: static;
			}
		}
    .success .ant-statistic-content-value {
      color: #31bd65!important;
      margin-left: 10px;
    }
    .danger .ant-statistic-content-value {
      color: #eb4b6d!important;
      margin-left: 15px;
    }
	}

  .marquee {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    width: 48%;
    margin-bottom: 20px;
    padding: 6px 0;
    background-color: #f5f8fa;
  }

  .marquee-content {
    display: inline-block;
    animation-name: marquee;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .marquee-item {
    margin-right: 50px; /* 通知之间的间隔 */
    letter-spacing: 1px; /* 字间距 */
    color: #888a8b;
  }

  @keyframes marquee {
    from { transform: translateX(0%); }
    to { transform: translateX(-50%); }
  }

</style>
<template>

  <!-- Sales By Country Table Card -->
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h6>兑换代理IP</h6>
        </a-col>
        <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
        </a-col>
      </a-row>
    </template>
    <!-- 充值功能区 -->
    <a-row type="flex" justify="center" align="middle" style="margin-bottom: 25px">
      <a-col :span="18">
        <a-form-item class="mb-10" :colon="false" style="width: 87%;margin:0 auto">
          <a-input placeholder="在此输入兑换码" v-model="code" v-decorator="['code',{ rules: [{ required: true, message: '请输入你的兑换码！' },]},]"/>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-button type="primary" class="mb-10" block style="width: 80%;" @click="submit">
          确 认
        </a-button>
      </a-col>
    </a-row>

<!--    <a-row type="flex" align="middle" style="margin-left: 23px;margin-top: 15px">-->
<!--      <a-col :span="24" :md="12">-->
<!--        <h6>如何获取兑换码？</h6>-->
<!--      </a-col>-->
<!--      <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">-->
<!--      </a-col>-->
<!--    </a-row>-->
<!--    <a-row type="flex" align="middle" style="margin-left: 23px;margin-bottom: 25px">-->
<!--      <span>24小时自助发码平台购买 →<a href="http://code.copyapes.com/" target="_blank">点击直达</a></span>-->
<!--    </a-row>-->

  </a-card>
  <!-- / Sales By Country Table Card -->


</template>

<script>
import http from "../../utils/http"
const key = 'updatable';

export default ({
  props: ['fetchData'],
  data() {
    return {
      code: '',

      // 提交状态
      isLoading: false,
    }
  },
  methods: {
    resetForm () {
      this.code = '';
    },
    async submit () {
      const code = this.code
      this.isLoading = true;
      this.$message.loading({ content: '兑换码校验中，请稍后...', key, duration: 0 });
      try {
        const response = await http.patch("/api/redeemcode/?token=" + localStorage.getItem("token"),{"code": code,});
        // console.log(response.data);
        if (response.data.code == 0) {
          this.$message.success({ content: response.data.detail, key, duration: 2 });
          // 服务器响应成功后，清空表单
          this.resetForm();
          this.fetchData();
          // 刷新当前页面
          // window.location.reload();
        } else {
          this.$message.error({ content: response.data.detail, key, duration: 2 });
        }
      }catch (error) {
        console.error(error);
        this.$message.error("服务器内部错误！请联系客服处理！");
      }
    },
  },
})

</script>

<style scoped>

</style>